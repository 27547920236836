import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Modal, Select, Tag, Tooltip, Typography } from 'antd';
import { isBoolean } from 'lodash';
import moment from 'moment';
import { PencilSimpleLine, Trash } from 'phosphor-react';
import React, { useRef, useState } from 'react';
import VideoPlaceholder from '../../../assets/images/imagePlaceholder.png';
import LiveLogo from '../../../assets/images/live-new.png';
import {
  ASSET_CATEGORY,
  LANGUAGE_COLOR,
  LIVE_VIDEO_OPTIONS,
  MODULES,
  ROUTES,
  STATUS_COLORS
} from '../../../common/constants';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import CustomCard from '../../component/card/Cscard';
import { DELETE_ASSET } from '../graphql/Mutations';
import { GET_ASSETS } from '../graphql/Queries';

const { confirm } = Modal;

const status = {
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  ERRORED: 'Errored',
  READY: 'Ready'
};

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  isLive
}) => ({
  filter: {
    limit,
    skip: offset,
    search,
    categoryKey: ASSET_CATEGORY?.VIDEO,
    ...(isBoolean(isLive) && { isLive })
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ assets }) => ({
  data: assets?.assets || [],
  count: assets?.count || 0
});

function Videos({ history }) {
  const [isLiveVideo, setIsLiveVideo] = useState('');
  const ref = useRef();
  const filterProps = useFilterBar();

  const [deleteVideo] = useMutation(DELETE_ASSET, {
    onError() {}
  });

  const handleShowModal = () => {
    history?.push(`${ROUTES?.VIDEOS}/add`, { isUpdate: false });
  };

  const handleEdit = (id) => {
    history.push(`${ROUTES?.VIDEOS}/${id}/edit`, {
      isUpdate: true,
      videoId: id
    });
  };

  const handlePreview = (item) => {
    if (ref.current.showPreview) {
      ref.current.showPreview({
        title: item.title,
        url: item.url,
        type: ASSET_CATEGORY.VIDEO
      });
    }
  };

  const handleDelete = async (id) => {
    confirm({
      title: 'Are you sure, you want to delete this video?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      async onOk() {
        deleteVideo({
          variables: { where: { id } }
        })
          .then(({ errors }) => {
            if (ref.current.removeItem && !errors) {
              ref.current.removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  const columns = [
    {
      title: 'Video',
      dataIndex: 'serviceImageThumbnail',
      key: 'serviceImageThumbnail',
      editable: true,
      width: '12%',
      render: (text, record) => (
        <img
          onClick={() => handlePreview(record)}
          className="group-img-table pointer"
          src={
            record?.serviceVideoThumbnail ??
            record?.serviceImageThumbnail ??
            VideoPlaceholder
          }
          alt={`${record?.title}`}
        />
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '22%',
      render: (title, record) => (
        <div className="d-flex align-center">
          {title}
          {record?.isLive && (
            <img src={LiveLogo} alt="live-logo" className="live-logo-table" />
          )}
        </div>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '22%'
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      width: '22%',
      render: (language) => {
        return (
          <>
            {language?.name ? (
              <Tag className="m-0 custom-tag" color={LANGUAGE_COLOR}>
                {language?.name}
              </Tag>
            ) : (
              '-'
            )}
          </>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '22%',
      render: (value) => (
        <Tag className="m-0 custom-tag" color={STATUS_COLORS[value]}>
          {status[value]}
        </Tag>
      )
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      width: '12%',
      render: (text, record) => (
        <div>
          <Tooltip title="Edit Video">
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(record?.id);
              }}
            />
          </Tooltip>
          <span onClick={(e) => e.stopPropagation()}>
            <Tooltip title="Delete Video">
              <DeleteOutlined onClick={() => handleDelete(record?.id)} />
            </Tooltip>
          </span>
        </div>
      )
    }
  ];

  return (
    <>
      <PageHeader menu={MODULES?.ASSETS} handleShowModal={handleShowModal} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Videos' }}
        extraFilters={
          <>
            <Typography.Text className="sort-by-title" type="secondary">
              Filter By
            </Typography.Text>
            <Select
              dropdownMatchSelectWidth={false}
              value={isLiveVideo}
              onChange={setIsLiveVideo}
            >
              {LIVE_VIDEO_OPTIONS?.map(({ name, value }) => (
                <Select.Option key={value} value={value}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </>
        }
      />
      <PageList
        ref={ref}
        filters={{ ...filterProps.filters, isLive: isLiveVideo }}
        listMode={filterProps.listMode}
        query={GET_ASSETS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        GridProps={{
          renderItem: (item) => (
            <CustomCard
              onClick={() => {
                handlePreview(item);
              }}
              isLive={item?.isLive}
              status={status[item.status]}
              statusColor={STATUS_COLORS[item.status]}
              alt={item?.title}
              src={
                item?.serviceVideoThumbnail ??
                item?.serviceImageThumbnail ??
                VideoPlaceholder
              }
              language={item?.language}
              title={moment(item?.createdAt).format('ll')}
              heading={item?.title}
              description={item?.description}
              primaryOnClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
                handleEdit(item?.id);
              }}
              secondaryOnClick={(e) => {
                e?.stopPropagation();
                e?.preventDefault();
                handleDelete(item?.id);
              }}
              primary={
                <>
                  <PencilSimpleLine size={16} />
                  Edit
                </>
              }
              secondary={
                <>
                  <Trash size={16} />
                  Delete
                </>
              }
            />
          )
        }}
        TableProps={{ columns }}
      />
    </>
  );
}

export default Videos;
